/* global $, ICAPP, Foundation */

$(document).foundation();

$(function () {
  /* =========================================================================
   1. General
  ========================================================================= */
  // Newsletter Validation
  $('form.ajax-form').submit(function formRequired(event) {
    var $this = $(this);
    var $requiredFields = $this.find('input[required]');
    var fail = false;
    var message = '';

    event.preventDefault();
    $requiredFields.each(function checkRequired(index, el) {
      if ($(el).val() === '') {
        fail = true;
        message = $(el).attr('data-error');
      }
    });
    if (fail === true) {
      $('#newsletterModal .lead').text(message);
      $('#newsletterModal').foundation('open');
      return false;
    }
    // Submit via AJAX
    $.post($this.attr('action'), $this.serialize(), function submitForm(data) {
      $('#newsletterModal .lead').text(data);
      $('#newsletterModal').foundation('open');
    });
    return true;
  });

  // Fitment
  $('#nav_close a.clear, #fitmentClear').on('click', function clearFitment(event) {
    event.preventDefault();
    ICAPP.setSession('', 'clear', '', '');
    $('#year option[value=""]').attr('selected', 'selected');
    $('#make option[value=""]').attr('selected', 'selected');
    $('#make').attr('disabled', 'disabled');
    $('#model option[value=""]').attr('selected', 'selected');
    $('#model').attr('disabled', 'disabled');
    $('#submodel option[value=""]').attr('selected', 'selected');
    $('#submodel').attr('disabled', 'disabled');
    $('#size option[value=""]').attr('selected', 'selected');
    $('#size').attr('disabled', 'disabled');
    $('#divResult').html('');
    $('#stryear').html('');
    $('#strmake').html('');
    $('#strmodel').html('');
    $('#strsubmodelopt').html('');
    $('#strsizetext').html('');
  });

  $('#tireFitmentClear').on('click', function clearFitment(event) {
    event.preventDefault();
    ICAPP.setSession('', 'clear', '', '', 'tires');
  });

  $('#checkFitmentClear').on('click', function clearFitment(event) {
    event.preventDefault();
    ICAPP.setSession('', 'clear', '', '', '');
  });

  ICAPP.initChange();

  $('.cart-open').magnificPopup({
    items: {
      src: '#cart-popup',
      type: 'inline'
    }
  });

  // Set hidden price input
  $('.frontWheel, .frontTire').on('change', function () {
    $('.price_1').attr('value', $(this).find(':selected').data('price'));
    var firstSelect = $(this).find(':selected').attr('value');
    var secondSelect = $('.rearWheel, .rearTire').find(':selected').attr('value');
    if (firstSelect == secondSelect) {
      $('.qty_2').attr('value', 4);
    } else {
      $('.qty_2').attr('value', 2);
    }
  });
  $('.rearWheel, .rearTire').on('change', function () {
    $('.price_2').attr('value', $(this).find(':selected').data('price'));
    var firstSelect = $('.frontWheel, .frontTire').find(':selected').attr('value');
    var secondSelect = $(this).find(':selected').attr('value');
    if (firstSelect == secondSelect) {
      $('.qty_2').attr('value', 4);
    } else {
      $('.qty_2').attr('value', 2);
    }
  });

  $('.tireFitment-open').magnificPopup({
    items: {
      src: '#tireFitment-popup',
      type: 'inline'
    }
  });

  $('.checkFitment-open').magnificPopup({
    items: {
      src: '#checkFitment-popup',
      type: 'inline'
    }
  });

  $('.fitment-open').magnificPopup({
    items: {
      src: '#fitment-popup',
      type: 'inline'
    }
  });

  $('#fitmentSubmit').on('click', function submitFitment() {
    ICAPP.setSession('', 'size', $('.size').html(), strSizeText);
  });

  $('#checkFitmentSubmit').on('click', function submitFitment() {
    if (($('.fitmentSize').val() == '') || ($('.fitmentSize').attr('disabled') == 'disabled')) {
      return;
    }
    var wheelImageID = $(this).parents('#checkFitment-popup').data('wheelimageid');
    var wheelID = $(this).parents('#checkFitment-popup').data('wheelid');
    var finishid = $(this).parents('#checkFitment-popup').data('finishid');

    ICAPP.setSession('', 'size', $('.size').html(), strSizeText, 'fitmentCheck', wheelImageID, wheelID, finishid);
  });

  $('#tireFitmentSubmit').on('click', function submitFitment() {
    ICAPP.setSession('', 'size', $('.size').html(), strSizeText, 'tires');
  });

  $('#homeVehicleSubmit').on('click', function submitFitment() {
    var size = $('.homeSizeSubmit');
    if (size.val() && size.val() !== 'Select Size') {
      size.change();
    }
  });
  // Mobile Accordion
  if ($('.accordion').length && Foundation.MediaQuery.atLeast('medium')) {
    $('.accordion').each(function openAccordion() {
      $(this).foundation('down', $(this).find('.accordion-content').first(), true);
    });
  }

  // Scroll to section
  $('.scroll-to').on('click', function scrollToElem(event) {
    var target = $($(this).attr('href'));
    if (target.length) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 1000);
    }
  });
  // search menu link slide toggle
  $('#search-link').click(function () {
    $('#search').slideToggle();
  });

  $('.searchBtn').magnificPopup({
    items: {
      src: '<div class="white-popup processing">' +
        '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
        '</div>',
      type: 'inline',
      modal: true
    },
    callbacks: {
      open: function () {
        $('#search-form').submit();
      }
    }
  });

  // Varify captcha
  $('.rfqSubmit').on('click', function () {
    if (grecaptcha.getResponse() == '') {
      var response = document.getElementById('captchaCheck');
      response.innerHTML = 'Captcha box not selected';
      return false;
    } else {
      return true;
    }
  });

  /* ======================================================================
   2.1 Home Page
  ====================================================================== */
  $('#slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    var backgroundImage = $('#slider').find('a[data-slick-index=' + currentSlide + ']').data('background');
    $('.hero').css('background-image', '//images.wheelmax.com/slides/' + backgroundImage);
  }).slick({
    arrows: false,
    fade: true,
    infinite: true,
    dots: true
  });
  $('#searchBy').change(function () {
    var value = $(this).val();
    $('#' + value)
      .removeClass('hide')
      .siblings('.search-tab')
      .addClass('hide');
    var $form = $('.product-filters form');
    if (value == 'tire-search') {
      $form.attr('action', '/tires/');
      $('fitmentClear').attr('id', 'clear-filters');
    }
    if (value == 'wheel-search') {
      $form.attr('action', '/wheels/');
      $('fitmentClear').attr('id', 'clear-filters');
    }
    if (value == 'vehicle-search') {
      $form.attr('action', '');
      $('clear-filters').attr('id', 'fitmentClear');
    }

  });
  $('#clear-filters').click(function () {
    $('.search-tab select').val('');
  });

  // Wheel Search
  var brand = '';
  var finish = '';
  var diam = '';

  function getAjax(URL, funct) {
    var strDomainAP = '/ajax/' + URL + '&funct=' + funct;
    try {
      $.ajax({
        url: strDomainAP,
        success: function (data) {
          if (funct === 'brand') {
            $('#finish').html(data);
            $('#finish').removeAttr('disabled');
          } else if (funct === 'finish') {
            $('#diam').html(data);
            $('#diam').removeAttr('disabled');
          } else {
            $('#wheel-search').html(data);
          }
        }
      });
    } catch (err) {
      window.location.reload();
    }
  }

  $('#wheel-search #brand').change(function () {
    brand = $(this).val();
    if (brand !== '') {
      $('#diam').attr('disabled', 'disabled');
      var url = 'getWheelSizes.cfm?brand=' + brand + '&configID=' + intConfigID;
      getAjax(url, 'brand');
    }
  });

  $('#wheel-search #finish').change(function () {
    finish = $(this).val();
    if (finish !== '') {
      var url = 'getWheelSizes.cfm?finish=' + finish + '&brand=' + brand + '&configID=' + intConfigID;
      getAjax(url, 'finish');
    }
  });

  $('#wheel-search #diam').change(function () {
    diam = $(this).val();
    if (diam !== '') {
      var url = '/wheels/?diam=' + diam + '&finish=' + finish + '&brand=' + brand;
      window.location.href = url;
    }
  });

  /* ======================================================================
   2.2 Brand Pages
  ====================================================================== */
  $('.wheel-banner').slick({
    arrows: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: randomNumber(),
  });
  // Generate random number from 1 to 5
  function randomNumber() {
    return Math.floor(Math.random() * 5);
  }

  // Function for Wheel Filter to change page to 1 when a brand is selected
  // on a page greater than 1
  function replaceUrlParam(url, key, value) {
    if (value == null)
      value = '';
    var pattern = new RegExp('\\b(' + key + '=).*?(&|$)');
    if (url.search(pattern) >= 0) {
      return url.replace(pattern, '$1' + value + '$2');
    }
    return url + (url.indexOf('?') > 0 ? '&' : '?') + key + '=' + value;
  }

  // Wheel filters
  $('#filters, #tireFilters').on('change', 'select', function (event) {
    event.preventDefault();
    // Get type of filter and current query
    var $this = $(this);
    var type = $this.attr('name');
    var query = window.location.search;
    var parts = document.location.href.split('/');

    // If this filter has already been applied, replace
    if (query.indexOf(type + '=') > -1) {
      var regex = new RegExp('(' + type + '=)([^&]+)?', 'ig');
      query = query.replace(regex, '$1' + $this.val());
      // If there are already other params
    } else if (query.indexOf('?') > -1) {
      query += '&' + type + '=' + $this.val();
      // If this is the only param
    } else {
      query += '?' + type + '=' + $this.val();
    }

    var elem = $this.parent().attr('id');
    // Set the page to 1
    if (window.location.href.match('page')) {
      query = replaceUrlParam(query, page, 1);
    }
    if ($this.parent().attr('id') == 'tireFilters') {
      if (window.location.href.match('tires/' + /\d+/) > -1) {
        window.location.href = '/tires/' + query;
      }
    } else if (window.location.href.match('wheels/' + /\d+/) > -1) {
      var urlSplit = document.location.href.split('/');
      var urlSection = urlSplit[4];
      if (urlSection > 0) {
        window.location.href = '/wheels/' + urlSection + '/' + query;
      } else {
        window.location.href = '/wheels/' + query;
      }
    } else {
      window.location.href = window.location.pathname + query;
    }
    $.magnificPopup.open({
      items: {
        src: '<div class="white-popup processing">' +
          '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
          '</div>'
      },
      closeBtnInside: false
    });
  });


  // ***** WHEEL BRANDS WHEEL FILTERS ***** //
  if ($('.wheel-filter').length) {

    // Disable 'width' when no diameter is available
    $('#wheel-diam').on('change', function () {
      if ($(this).val() !== '') {
        $('#wheel-width').prop('disabled', false);
      } else {
        $('#wheel-width').prop('disabled', true);
      }
    });

    $('.wheel-filter select').on('change', function () {
      $('.js-show-search').removeClass('hidden');
    });

    $('.wheel-filter').on('submit', function (event) {
      event.preventDefault();

      $.magnificPopup.open({
        items: {
          src: '<div class="white-popup processing">' +
            '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
            '</div>'
        },
        closeBtnInside: false
      });

      var filterURL = $('.wheel-filter :input')
        .filter(function (index, element) {
          return $(element).val() != '';
        })
        .serialize();

      window.location.href = '/wheels/?' + filterURL;
    });
  }



  var $img = $('.parallax .img');
  var $heading = $('.parallax h1');
  var scroller;
  var wh = $(window).height();



  // requestAnimationFrame Shim
  window.requestAnimFrame = (function animFramPoly() {
    return window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function animTimout(callback) {
        window.setTimeout(callback, 1000 / 60);
      };
  }());

  // Scroller
  function Scroller() {
    this.latestKnownScrollY = 0;
    this.ticking = false;
  }

  Scroller.prototype = {
    // Initialize
    init: function init() {
      window.addEventListener('scroll', this.onScroll.bind(this), false);
    },

    // Capture Scroll
    onScroll: function onScroll() {
      this.latestKnownScrollY = window.scrollY;
      this.requestTick();
    },

    // Request a Tick
    requestTick: function requestTick() {
      if (!this.ticking) {
        window.requestAnimFrame(this.update.bind(this));
      }
      this.ticking = true;
    },

    // Update
    update: function update() {
      var currentScrollY = this.latestKnownScrollY;

      // Do The Dirty Work Here
      var imgScroll = currentScrollY / 2;
      var headScroll = currentScrollY / 3;

      this.ticking = false;

      $img.css({
        'transform': 'translateY(' + imgScroll + 'px)',
        '-moz-transform': 'translateY(' + imgScroll + 'px)',
        '-webkit-transform': 'translateY(' + imgScroll + 'px)'
      });

      $heading.css({
        'transform': 'translateY(' + headScroll + 'px)',
        '-moz-transform': 'translateY(' + headScroll + 'px)',
        '-webkit-transform': 'translateY(' + headScroll + 'px)'
      });
    }
  };

  // Attach!
  if ($('.parallax .img').length || $('.parallax h1').length) {
    scroller = new Scroller();
    scroller.init();
  }


  /* ======================================================================
   2.3 Wheels Page
  ====================================================================== */
  // Quick View Popup
  $('.quickview-open').click(function () {
    var $this = $(this);
    var finishName = $this.data('finishname');
    var wheelName = $this.data('wheelname');
    var brandName = $this.data('brandname');
    var wheelImage = $this.data('wheelimage');
    var wheelLink = $this.data('wheellink');
    var wheelSizes = $this.data('sizes');
    $('#quickview-popup')
      .find('.popup-brandname')
      .html(brandName);
    $('#quickview-popup')
      .find('.popup-wheelname')
      .html(wheelName);
    $('#quickview-popup')
      .find('.popup-wheelfinish')
      .html(finishName);
    $('#quickview-popup')
      .find('.popup-sizes')
      .html(wheelSizes);
    $('#quickview-popup')
      .find('.popup-wheelimage')
      .attr('src', wheelImage);
    $('#quickview-popup')
      .find('.popup-wheellink')
      .attr('href', wheelLink);

    $.magnificPopup.open({
      items: {
        src: '#quickview-popup',
        type: 'inline'
      }
    });
  });

  // Insert vehicle image into vehicle bar
  function insertVehicleImage() {
    var vehicleData = $('.vehicleData').data();
    var getVehicle = 'https://iconfigurators.app/api/?function=getVehiclePreview';
    getVehicle += '&year=' + vehicleData.year;
    getVehicle += '&make=' + vehicleData.make;
    getVehicle += '&model=' + vehicleData.model;
    getVehicle += '&submodel=' + vehicleData.submodel;
    getVehicle += '&option=' + vehicleData.option;
    getVehicle += '&key=' + vehicleData.key;
    $.getJSON(getVehicle, function (data) {
      if (data.vehicles[0] != null) {
        var returnedImgUrl = data.vehicles[0].previewImage;
        var returnedImg = '<img ';
        returnedImg += 'src="' + returnedImgUrl + '"';
        returnedImg += 'alt="' + vehicleData.year + ' ' + vehicleData.make + ' ' + vehicleData.model + '"';
        returnedImg += '/>';
        $('.vehicleData').append(returnedImg);
      }
    });
  }

  if ($('.setVehicleImg').length > 0) {
    insertVehicleImage();
  }

  $('.js-wheelCheck').on('click', function (e) {
    // Exclude the popup buttons from activating spinner
    if (e.target.classList.contains('js-popupButton')) {
      return false;
    }
    $.magnificPopup.open({
      items: {
        src: '<div class="white-popup processing">' +
          '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
          '</div>'
      }
    });
  });

  /* ======================================================================
   2.4 Wheel Detail Pages
  ====================================================================== */
  // Slider and popup for main image
  function initializeMainImage() {
    $('.main-image').magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      }
    }).slick({
      arrows: false,
      asNavFor: '.alt-images',
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });

    $('.alt-images').slick({
      arrows: false,
      asNavFor: '.main-image',
      dots: false,
      focusOnSelect: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1
    });
  }

  initializeMainImage();

  // Quote popup
  $('.open-quote-popup').magnificPopup({
    items: {
      src: '#quote-popup',
      type: 'inline'
    }
  });


  // Reset Cart Dropdowns
  $('.defaultSelect').prop('selectedIndex', 0);


  // Wheel gallery images
  $('.wheel-gallery-photo').magnificPopup({
    type: 'image',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    }
  });

  // Gallery View Popup
  $('.galleryView-open').click(function () {
    var $this = $(this);
    var wheelimage = $this.data('wheelimage');
    var wheel = $this.data('wheel');
    var car = $this.data('car');
    var finish = $this.data('finish');
    var wsize = $this.data('wsize');
    var tire = $this.data('tire');
    var tsize = $this.data('tsize');
    var suspension = $this.data('suspension');
    $('#galleryView-popup')
      .find('.popup-wheelimage')
      .attr('src', wheelimage);
    if (wheel != '') {
      $('#galleryView-popup')
        .find('.popup-wheel')
        .html('Wheel: ' + wheel + '<br/>');
    }
    if (suspension != '') {
      $('#galleryView-popup')
        .find('.popup-wsize')
        .html('Wheel Size: ' + wsize + '<br/>');
    }
    if (tsize != '') {
      $('#galleryView-popup')
        .find('.popup-tsize')
        .html('Tire Size: ' + tsize + '<br/>');
    }
    if (car != '') {
      $('#galleryView-popup')
        .find('.popup-car')
        .html(car + '<br/>');
    }
    if (finish != '') {
      $('#galleryView-popup')
        .find('.popup-finish')
        .html('Finish: ' + finish + '<br/>');
    }
    if (tire != '') {
      $('#galleryView-popup')
        .find('.popup-tire')
        .html('Tire: ' + tire + '<br/>');
    }
    if (suspension != '') {
      $('#galleryView-popup')
        .find('.popup-suspension')
        .html('Suspension: ' + suspension + '<br/>');
    }
    $.magnificPopup.open({
      items: {
        src: '#galleryView-popup',
        type: 'inline'
      }
    });
  });

  // Wheel Detail Quote Popup
  $('.open-detail-quote-popup').magnificPopup({
    items: {
      src: '#detail-quote-popup',
      type: 'inline'
    }
  });

  // Tire Detail Quote Popup
  $('.open-tireDetail-quote-popup').magnificPopup({
    items: {
      src: '#tireDetail-quote-popup',
      type: 'inline'
    }
  });


  // Add Accessory to cart
  $('.accessory-open').on('click', function () {
    $('.wheelFlag').prop('value', 1);
    var frontWheel = $('.partnum_1').val();
    var rearWheel = $('.partnum_2').val();
    if (frontWheel == rearWheel) {
      $('.qty_2').attr('value', 4);
    }
    $('#cart-wheelForm').submit();
  });

  $('.wheelUpdate').on('click', function () {
    $('.wheelFlag').prop('value', 0);
  });

  $('#cart-wheelForm').on('submit', function (e) {
    if ($(this).find('.wheelFlag').prop('value') == 1) {
      e.preventDefault();
      $.magnificPopup.open({
        items: {
          src: '#accessory-popup',
          type: 'inline'
        }
      });
    } else {
      return true;
    }
  });

  function updateCart() {
    $.each($('#cart-wheelForm').children('.item'), function (index) {
      var itemCount = index + 2;
      $.each($(this).children('input'), function () {
        $(this).prop('name', function (_, name) {
          return name.replace(new RegExp('x|\\d'), itemCount + 1);
        });
      });
    });
  }

  function updatePackage(itemNumber) {
    $.each($('#package-Form[data-item="' + itemNumber + '"]').children('.item'), function (index) {
      var itemCount = index + 4;
      $.each($(this).children('input'), function () {
        $(this).prop('name', function (_, name) {
          return name.replace(new RegExp('x|\\d'), itemCount + 1);
        });
      });
    });
  }

  $(':checkbox').on('click', function () {
    var copy = $(this).siblings('.item').clone();
    var copyItem = $(this).data('item');
    if ($(this).is(':checked')) {
      $('#cart-wheelForm').append(copy);
      updateCart();
    } else {
      $('#cart-wheelForm' + ' .' + copyItem).remove('.' + copyItem);
      updateCart();
    }
  });

  $('.accessoryCheck').on('click', function () {
    var copy = $(this).siblings('.item').clone();
    var copyItem = $(this).data('item');
    var itemNumber = $('#accessory-popup').data('item');
    if ($(this).is(':checked')) {
      $('#package-Form[data-item="' + itemNumber + '"]').append(copy);
      updatePackage(itemNumber);
    } else {
      $('#package-Form[data-item="' + itemNumber + '"]' + ' .' + copyItem).remove('.' + copyItem);
      updatePackage(itemNumber);
    }
  });

  $('.packageContent').on('click', '.cancelBtn', function (e) {
    e.stopPropagation();
    $.magnificPopup.close();
  });

  $('.packageUpdate').on('click', function () {
    $(this).siblings('.packageFlag').prop('value', 0);
  });

  $('.packageContent').on('click', '.updatePackage', function (e) {
    $(this).siblings('.packageFlag').prop('value', 1);

    $('.wheelOne-desc').val($('.frontDesc').val());
    $('.wheelTwo-desc').val($('.rearDesc').val());

    $('.wheelOne-part').val($('.partnum_1').val());
    $('.wheelTwo-part').val($('.partnum_2').val());

    $('.wheelOne-qty').val($('.frontQty').val());
    $('.wheelTwo-qty').val($('.rearQty').val());

    $('.wheelOne-make').val($('.frontMake').val());
    $('.wheelTwo-make').val($('.rearMake').val());

    $('.wheelOne-price').val($('.frontPrice').val());
    $('.wheelTwo-price').val($('.rearPrice').val());

    var frontPrice = $(this).parents('form').find('.frontPackagePrice');
    var rearPrice = $(this).parents('form').find('.rearPackagePrice');
    $('.price_3').val(frontPrice.children('option:selected').data('price'));
    $('.price_4').val(rearPrice.children('option:selected').data('price'));
    if (frontPrice.val() == rearPrice.val()) {
      $('.qty_4').val('4');
    } else {
      $('.qty_4').val('2');
    }
    var frontWheelPart = $(this).parents('form').find('.wheelOne-part').val();
    var rearWheelPart = $(this).parents('form').find('.wheelTwo-part').val();
    if (frontWheelPart == rearWheelPart) {
      $('.qty_2').val('4');
    } else {
      $('.qty_2').val('2');
    }
    if ($(this).siblings('.packageFlag').prop('value') == 1) {
      e.preventDefault();
      var formValue = $(this).parents('form').data('item');
      $('.packageUpdate').prop('for', 'form-submit-' + formValue);
      $('#accessory-popup').attr('data-item', formValue);
      $.magnificPopup.open({
        items: {
          src: '#accessory-popup',
          type: 'inline'
        }
      });
      return false;
    } else {
      return true;
    }
  });

  // Get Tire Packages
  var tireSizeFront = '';
  var tireSizeRear = '';

  function getAjaxPackage(URL, funct) {
    var strDomainAP = '/ajax/' + URL + '&funct=' + funct;
    try {
      $.ajax({
        url: strDomainAP,
        success: function (data) {
          $('.packageContent').html(data);
          $.magnificPopup.open({
            items: {
              src: '#package-popup',
              type: 'inline'
            }
          });
        }
      });
    } catch (err) {
      window.location.reload();
    }
  }

  // $('.packageSelect').change(function () {
  // 	var selected = $(this).find('option:selected');
  // 	tire = selected.data('tire');
  // 	rear = selected.data('rear');
  // 		var url = 'getTirePackage.cfm?tireSize=' + tire + '&tireSizeRear=' + rear + '&configID=' + intConfigID;
  // 		getAjaxPackage(url, 'GetTirePackageQRY');
  // });

  $('.package-open').on('click', function () {
    tireSizeFront = $('.partnum_1').data('tire');
    tireSizeRear = $('.partnum_1').data('rear');
    var url = 'getTirePackage.cfm?tireSize=' + tireSizeFront + '&tireSizeRear=' + tireSizeRear + '&configID=' + intConfigID;
    getAjaxPackage(url, 'GetTirePackageQRY');
  });

  $('.frontWheel, .rearWheel').change(function () {
    var frontVal = $('.frontWheel').find('option:selected').attr('value');
    var rearVal = $('.rearWheel').find('option:selected').attr('value');
    if ((frontVal != '') && (rearVal != '')) {
      $('.packageBtn').addClass('package-open');
    } else {
      $('.packageBtn').removeClass('package-open');
    }
  });

  $('.packageBtn').on('click', function (e) {
    var frontVal = $('.frontWheel').find('option:selected').attr('value');
    var rearVal = $('.rearWheel').find('option:selected').attr('value');
    if ((frontVal == '') || (rearVal == '')) {
      alert('Please Select a front and rear wheel');
    } else {
      e.stopPropagation();
      $.magnificPopup.open({
        items: {
          src: '#package-popup',
          type: 'inline'
        }
      });
    }
  });
  // Match package select drop downs
  $('.packageContent').on('change', '.frontTirePackage, .rearTirePackage', function () {
    var firstSelect = '';
    var secondSelect = '';

    firstSelect = $('option:selected', this).attr('value');
    secondSelect = $(this).closest('.drop').siblings('.drop').children('select').children('option');
    secondSelect.each(function () {
      if ($(this).attr('value') == firstSelect) {
        $(this).prop('selected', true);

        $('.price_4').val($(this).data('price'));
      }

      var firstSelectVal = $(this).find(':selected').attr('value');
      var secondSelectVal = secondSelect.find(':selected').attr('value');
      if (firstSelectVal == secondSelectVal) {
        $('.qty_4').attr('value', 4);
      } else {
        $('.qty_4').attr('value', 2);
      }
    });

    $('.price_3').val($('option:selected', this).data('price'));

  });

  // Show/Hide excess wheel information
  $(document).ready(function () {
    if ($('.wheel-galleryImages > li').length > 6) {
      $('.wheel-galleryShowButton').removeClass('hidden');
    }
    if (getRows('.wheel-paragraph--limit') > 5) {
      $('.wheel-paragraph--limit').addClass('wheel-paragraph--height');
      $('.wheel-paragraphShowButton').removeClass('hidden');
    }
    if (getRows('.wheel-size--limit') > 5) {
      $('.wheel-size--limit').addClass('wheel-size--height');
      $('.wheel-sizeShowButton').removeClass('hidden');
    }
  });

  // Show/Hide excess paragraph lines
  function getRows(selector) {
    var height = $(selector).height();
    var line_height = $(selector).css('line-height');
    height = parseFloat(height);
    line_height = parseFloat(line_height);
    var rows = height / line_height;
    return Math.round(rows);
  }

  function toggleButtons(classSelect, cssSelect, showBtnToggle, hideBtnToggle) {
    $(classSelect).toggleClass(cssSelect);
    $(showBtnToggle).toggleClass('hidden');
    $(hideBtnToggle).toggleClass('hidden');
  }

  $('.wheel-paragraphShowButton, .wheel-paragraphHideButton').on('click', function () {
    toggleButtons('.wheel-paragraph--limit', 'wheel-paragraph--height', '.wheel-paragraphShowButton', '.wheel-paragraphHideButton');
  });

  $('.wheel-galleryShowButton, .wheel-galleryHideButton').on('click', function () {
    toggleButtons('.wheel-galleryImages > li', 'wheel-galleryList', '.wheel-galleryShowButton', '.wheel-galleryHideButton');
  });
  $('.wheel-sizeShowButton, .wheel-sizeHideButton').on('click', function () {
    toggleButtons('.wheel-size--limit', 'wheel-size--height', '.wheel-sizeShowButton', '.wheel-sizeHideButton');
  });

  // Get see on vehicle image
  function detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk) {
    var urlOptions = [
      'year=' + year,
      'make=' + make,
      'model=' + model,
      'drivebody=' + encodeURIComponent(drivebody),
      'submodel=' + submodel,
      'wheel=' + wheel,
      'color=' + color,
      'fmk=' + fmk,
      'ID=' + configid
    ];
    try {
      $.getJSON('https://www.iconfigurators.app/ap-json/ap-image-fgap-fmk-part.aspx?' + urlOptions.join('&'), function getCarImage(data) {
        if (data.Result > 0) {
          car.find('img').attr('src', data.img[0].src);

          $('#vehicle-colors').html('');
          var colorIds = data.img[0].colorID;
          var colorNames = data.img[0].colorName;

          for (var index = 0; index < colorIds.length; index++) {
            if (colorIds[index] == color) {
              $('#vehicle-colors').append('<option value="' + colorIds[index] + '" selected>' + colorNames[index] + '</option>');
            } else {
              $('#vehicle-colors').append('<option value="' + colorIds[index] + '">' + colorNames[index] + '</option>');
            }
          }

          // Create body style select
          $('#vehicle-body').html('');
          var bodyNames = data.img[0].BodyStyle;

          for (var j = 0; j < bodyNames.length; j++) {
            if (bodyNames[j] === body) {
              $('#vehicle-body').append('<option data-id="' + j + '" value ="' + bodyNames[j] + '" selected>' + bodyNames[j] + '</option>');
            } else {
              $('#vehicle-body').append('<option data-id="' + j + '" value ="' + bodyNames[j] + '" >' + bodyNames[j] + '</option>');
            }
          }

          $('#see-on-vehicle-detail > a').attr('href', function replaceUrl(i, a) {
            return a.replace(/(&body=)([0-9]+)?(&color=)([0-9]+)?/ig, '$1' + $('#vehicle-body').find(':selected').data('id') + '$3' + $('#vehicle-colors').val());
          });

        } else {
          car.find('.img-wrapper')
            .addClass('no-fitment')
            .find('.error')
            .text('We do not currently have an image of this wheel on your vehicle. Check back soon.');
        }
      });
    } catch (err) {
      car.find('.img-wrapper')
        .addClass('no-fitment')
        .find('.error')
        .text('Error Loading Image for Your Vehicle');
    }
  }

  // Set Dropdowns
  function setDropdownHandlers(car, year, make, model, drivebody, submodel, configid, fmk) {
    // Bind the color select
    $('#vehicle-colors').change(function changeColor() {
      var body = $('#vehicle-body').val();
      var color = $('#vehicle-colors').val();
      var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
      $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
        return a.replace(/(&color=)([0-9]+)?/ig, '$1' + color);
      });
      detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk);
    });
    // Bind the body select
    $('#vehicle-body').change(function changeBody() {
      var body = $('#vehicle-body').val();
      var color = 0;
      var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
      $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
        return a.replace(/(&body=)([0-9]+)?(&color=)([0-9]+)?/ig, '$1' + $('#vehicle-body').find(':selected').data('id') + '$3');
      });
      detailCarImage(car, year, make, model, drivebody, submodel, wheel, color, body, configid, fmk);
    });
  }

  // Check if vehicle is set in session first
  if ($('#see-on-vehicle-detail').length) {
    var car = $('#see-on-vehicle-detail');
    var year = car.data('year');
    var make = car.data('make');
    var model = car.data('model');
    var drivebody = car.data('drivebody');
    var submodel = car.data('submodel');
    var wheel = car.attr('data-wheel');
    var configid = car.data('config');
    var option = car.data('option');
    var fmk = car.data('option');
    if (typeof option === 'undefined') {
      option = '';
    }
    detailCarImage(car, year, make, model, drivebody, submodel, wheel, 0, '', configid, fmk);
    setDropdownHandlers(car, year, make, model, drivebody, submodel, configid, fmk);
  }

  // Click on finish images
  $('.detail-thumbs a').click(function changeFinish(e) {
    // e.preventDefault();
    var $this = $(this);

    // Update main image with new finish
    $.get('/ajax/getFinishImages.cfm?id=' + $this.data('id'), function getFinishes(data) {
      // $('#detail-top .images').html(data);
      initializeMainImage();
      $('.brand-details_lugs').text($this.data('lugs') + ' Lug');
    });
    $('#finish').text($this.find('.finish-name').text());

    // Replace see on image vehicle
    if ($('#see-on-vehicle-detail').length) {
      car = $('#see-on-vehicle-detail');
      car.attr('data-wheel', $this.attr('data-wheel'));
      year = car.data('year');
      make = car.data('make');
      model = car.data('model');
      submodel = car.data('submodel');
      wheel = $this.attr('data-wheel');
      configid = car.data('config');
      option = car.data('option');
      var color = $('#vehicle-colors').val();
      var body = $('#vehicle-body').val();
      if (typeof option === 'undefined') {
        option = '';
      }

      car.children('a').attr('href', function replaceUrl(i, a) {
        return a.replace(/(wheelimage=)([0-9]+)?/ig, '$1' + $this.data('id'));
      });

      if (!$this.attr('data-wheel')) {
        car.find('.img-wrapper')
          .addClass('no-fitment')
          .find('.error')
          .text('Current finish/lug does not fit selected vehicle');
      } else {
        detailCarImage(car, year, make, model, submodel, wheel, color, body, configid, option);
        car.find('.img-wrapper')
          .removeClass('no-fitment');
      }

      setDropdownHandlers(car, year, make, model, submodel, configid, option);
    }
  });

  // 360 view
  var $s360 = $('#iframe360');
  var $wheelMain = $('.details-360');
  $s360.height($wheelMain.width());

  $('#wheel a.video').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    iframe: {
      patterns: {
        youtube: {
          index: 'youtube.com/',
          id: function (url) {
            var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
            if (!m || !m[1]) return null;
            return m[1];
          },
          src: '//www.youtube.com/embed/%id%?autoplay=1&amp;rel=0'
        },
        vimeo: {
          index: 'vimeo.com/',
          id: function (url) {
            var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
            if (!m || !m[5]) return null;
            return m[5];
          },
          src: '//player.vimeo.com/video/%id%?autoplay=1'
        }
      }
    },
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });


  /* ======================================================================
   3.1  Gallery Landing Page
  ====================================================================== */

  // Gallery Quote Popup
  /*
  $('.gallery-quote-popup').magnificPopup({
    type: 'inline'
  });
*/
  $('#gallery a.video, #videos a, .popup-video').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });
  // Header Filters
  $('#gallery-make').change(function changeMake() {
    $('#gallery-model').val('');
    $('#gallery-brand').val('');
    $('#gallery-search').submit();
  });

  $('#gallery-model').change(function changeModel() {
    $('#gallery-brand').val('');
    $('#gallery-search').submit();
  });

  $('#gallery-brand').change(function changeBrand() {
    $('#gallery-search').submit();
  });

  if ($.fn.infinitescroll) {
    $('#gallery .gallery-list').infinitescroll({
      navSelector: 'div.navigation',
      nextSelector: 'div.navigation a:first',
      itemSelector: '#gallery .gallery-list div',
      loading: {
        finishedMsg: '<em>All Galleries Loaded</em>',
        img: '',
        msgText: '<i id="gallery-spinner" class="fa fa-spinner fa-spin"></i>'
      },
      bufferPx: 500
    });
  }

  /* ======================================================================
     3.2  Gallery Detail Page
  ====================================================================== */
  $('#gallery-main-image').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    }
  });
  /* ======================================================================
   4.1  WheelBuilder Page
  ====================================================================== */
  // Toggles the z-index of the wheelbuilder so the nav can sit on top of the other elements.
  $('.view-submenu').hover(
    function () {
      $('#builder_wrapper').addClass('negative-z-index');
      $(this).children('.submenu').show();
    }, function () {
    $('#builder_wrapper').removeClass('negative-z-index');
    $(this).children('.submenu').hide();
  }
  );
  /* ======================================================================
   5.2  Contact Page
  ====================================================================== */
  $('#vchrCountry select').change(function changeCountry() {
    $.get('/ajax/getStates.cfm?country=' + $(this).val(), function getStates(data) {
      $('#vchrState select').html(data).prop('disabled', false);
    });
  });
  $('#form-left form').submit(function checkRequired() {
    var fail = false;
    var message = '';
    var errorCount = 0;
    var name = '';
    var title = '';
    $('#form-left form [required]').each(function checkFields(index, element) {
      var $element = $(element);
      if ($element.val() === '') {
        $element.css('background', 'red');
        fail = true;
        errorCount++;
        name = $element.siblings('label').replace('*', '');
        message += name + ' is required. ';
      }
    });
    if (fail) {
      title = $(this).attr('data-title');
      $('#modal').html('<p>Form submission failed for the following reason(s):' + message + '</p>')
        .dialog({
          minHeight: 150,
          width: 300,
          modal: true,
          title: title,
          closeText: 'X',
          buttons: {
            Okay: function closeModal() {
              $(this).dialog('close');
            }
          }
        });
      return false;
    }
    return true;
  });
});
/* ======================================================================
 5.2  Tire Page
====================================================================== */
// Tire Search
var width = '';
var aspect = '';
var tirediam = '';
var tirebrand = '';

function getAjax(URL, funct) {
  var strDomainAP = '/ajax/' + URL + '&funct=' + funct;
  try {
    $.ajax({
      url: strDomainAP,
      success: function (data) {
        if (funct === 'width') {
          $('#aspect').html(data);
          $('#aspect').removeAttr('disabled');
        } else if (funct === 'aspect') {
          $('#tirediam').html(data);
          $('#tirediam').removeAttr('disabled');
        } else {
          $('#tireResults').html(data);
        }
      }
    });
  } catch (err) {
    window.location.reload();
  }
}

$('#tire-filters #width').change(function () {
  width = $(this).val();
  if (width !== '') {
    $('#tirediam').attr('disabled', 'disabled');
    var url = 'getTireSizes.cfm?width=' + width + '&configID=' + intConfigID;
    if (getQueryVariable('tirebrand') != '') {
      tirebrand = getQueryVariable('tirebrand');
      url += '&tirebrand=' + tirebrand;
    }
    getAjax(url, 'width');
  }
});

$('#tire-filters #aspect').change(function () {
  aspect = $(this).val();
  if (aspect !== '') {
    var url = 'getTireSizes.cfm?aspect=' + aspect + '&width=' + width + '&configID=' + intConfigID;
    if (getQueryVariable('tirebrand') != '') {
      tirebrand = getQueryVariable('tirebrand');
      url += '&tirebrand=' + tirebrand;
    }
    getAjax(url, 'aspect');
  }
});

$('#tire-filters #tirediam').change(function () {
  tirediam = $(this).val();
  if (tirediam !== '') {
    var url = '/tires/?aspect=' + aspect + '&width=' + width + '&tirediam=' + tirediam;
    if (getQueryVariable('tirebrand') != '') {
      tirebrand = getQueryVariable('tirebrand');
      url += '&tirebrand=' + tirebrand;
    }
    window.location.href = url;
  }
  $.magnificPopup.open({
    items: {
      src: '<div class="white-popup processing">' +
        '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
        '</div>'
    }
  });
});

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

$('#tire-filters #tirebrand').change(function () {
  // Get type of filter and current query
  var $this = $(this);
  var type = $this.attr('name');
  var url = window.location.search;
  // If this filter has already been applied, replace
  if (url.indexOf(type + '=') > -1) {
    var regex = new RegExp('(' + type + '=)([^&]+)?', 'ig');
    url = url.replace(regex, '$1' + $this.val());
    // If there are already other params
  } else if (url.indexOf('?') > -1) {
    url += '&' + type + '=' + $this.val();
    // If this is the only param
  } else {
    url += '?' + type + '=' + $this.val();
  }
  if (window.location.href.match('tires/' + /\d+/) > -1) {
    window.location.href = '/tires/' + url;
  } else {
    window.location.href = window.location.pathname + url;
  }
  $.magnificPopup.open({
    items: {
      src: '<div class="white-popup processing">' +
        '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
        '</div>'
    }
  });
});

// TIRE RFQ Filter
var diam = '';
var tire = '';

function getAjaxTireRFQ(URL, funct) {
  var strDomainAP = '/ajax/' + URL + '&funct=' + funct;
  try {
    $.ajax({
      url: strDomainAP,
      success: function (data) {
        $('.rearTireSelect').html(data);
      }
    });
  } catch (err) {
    window.location.reload();
  }
}

$('.frontTireSelect').change(function () {
  var selected = $(this).find('option:selected');
  diam = selected.data('diam');
  tire = selected.data('tire');
  var url = 'getRearTires.cfm?ID=' + tire + '&diam=' + diam + '&configID=' + intConfigID;
  getAjaxTireRFQ(url, 'GetRFQDataQRY');
});
