var ICAPA = function ICAPA() {
  // function to get json data
  var getJSON = function getJSON(URL) {
    try {
      $.ajax({
        dataType: 'jsonp',
        url: '//ver1.iconfigurators.app/ap-json/' + URL,
        success: function (data) {
          eval(data);
        }
      });
    } catch (err) {
      window.location.reload();
    }
  };
  var createYear = function createYear(data) {

    var intResults = (data.Result - 1);
    var selectYear = '<option value="">SELECT YEAR</option>';
    for (var i = 0; i <= intResults; i++) {
      selectYear = selectYear + '<option value="' + data.years[i].year + '">' + data.years[i].year + '</option>';
    }
    $('.year').html(selectYear);
    $(".year option[value='" + intYear + "']").attr('selected', 'selected');
  };
  var createMake = function createMake(data) {
    var intResults = (data.Result - 1);
    var selected = '';
    var selectMake = '<option value="">SELECT MAKE</option>';
    for (var i = 0; i <= intResults; i++) {
      if (intMake == data.makes[i].makeid) {
        var selected = 'selected';
      } else {
        var selected = '';
        selectMake = selectMake + '<option value="' + data.makes[i].makeid + '" ' + selected + ' >' + data.makes[i].make + '</option>';
      }
    }
    $('.make').html(selectMake);
    $('.make').removeAttr('disabled');
    if (intModel == 0) {
      $('.model option[value=""]').attr('selected', 'selected');
      $('.submodel option[value=""]').attr('selected', 'selected');
      $('.model').attr('disabled', 'disabled');
      $('.submodel').attr('disabled', 'disabled');
      $('.size option[value=""]').attr('selected', 'selected');
      $('.size').attr('disabled', 'disabled');
    }
  };

  var createModel = function createModel(data) {
    var intResults = (data.Result - 1);
    var selectModel = ' <option selected="" value="">SELECT MODEL</option>';
    for (var i = 0; i <= intResults; i++) {
      if (intModel == data.models[i].modelid) {
        var selected = 'selected';
      } else {
        var selected = '';
        selectModel = selectModel + '<option value="' + data.models[i].modelid + '"  ' + selected + ' >' + data.models[i].model + '</option>';
      }
    }
    $('.model').html(selectModel);
    $('.model').removeAttr('disabled');
    $('.model').removeClass('selector disabled');
    if (intSubModel == 0) {
      $('.submodel option[value=""]').attr('selected', 'selected');
      $('.submodel').attr('disabled', 'disabled');
      $('.submodel').addClass('disabled');
      $('.size option[value=""]').attr('selected', 'selected');
      $('.size').attr('disabled', 'disabled');
    }
  };
  var createSubModel = function createSubModel(data) {
    var intResults = (data.Result - 1);
    var selectSubModel = '<option selected="" value="">SELECT SUBMODEL</option>';

    for (var i = 0; i <= intResults; i++) {

      if (intSubModel == data.submodels[i].submodelid) {
        var selected = 'selected';
      } else {
        var selected = '';
      }

      selectSubModel = selectSubModel + '<option value="' + data.submodels[i].submodelid + '" ' + selected + ' >' + data.submodels[i].submodel + '</option>';
    }

    $('.submodel').html(selectSubModel);
    $('.submodel').removeAttr('disabled');
    $('.size option[value=""]').attr('selected', 'selected');
    $('.size').attr('disabled', 'disabled');
  };
  var createAccessSubModel = function createSubModel(data) {
    var intResults = (data.Result - 1);
    selectAccessSubModel = '<option selected="" value="">SELECT SUBMODEL</option>';
    for (var i = 0; i <= intResults; i++) {

      if (intAccessSubModel == data.submodels[i].submodelid) {
        var selected = 'selected';
      } else {
        var selected = '';
      }
      selectAccessSubModel = selectAccessSubModel + '<option value="' + data.submodels[i].submodelid + '" ' + selected + ' >' + data.submodels[i].submodel + '</option>';
    }
    $('.access-submodel').html(selectAccessSubModel);
    $('.access-submodel').removeAttr('disabled');

  };
  var createSize = function createSize(data) {
    var objMMSMSZ = data;
    // alert('sizecreate');
    if (objMMSMSZ.Result > 0) {
      var intResults = (objMMSMSZ.Result - 1);
      var selectSizeSZ = '<option value="" Selected >SELECT SIZE</option>';
      for (var i = 0; i <= intResults; i++) {
        selectSizeSZ = selectSizeSZ + '<option value="' + objMMSMSZ.sizes[i].sizeid + '">' + objMMSMSZ.sizes[i].size + '</option>';
      }
      $('.size').html(selectSizeSZ);
      $('.size').removeAttr('disabled');



    } else
      alert('Sorry, we do not have wheels that fit your vehicle.');

  };
  return {
    getJSON: getJSON,
    createYear: createYear,
    createMake: createMake,
    createModel: createModel,
    createSubModel: createSubModel,
    createSize: createSize
  };
}();

var yearObj_APA = function yearObj_APA(JSONData) { ICAPA.createYear(JSONData); };
var makeObj_APA = function makeObj_APA(JSONData) { ICAPA.createMake(JSONData); };
var modelObj_APA = function modelObj_APA(JSONData) { ICAPA.createModel(JSONData); };
var submodelObj_APA = function submodelObj(JSONData) { ICAPA.createSubModel(JSONData); };
var accesssubmodelObj_APA = function accesssubmodelObj(JSONData) { ICAPA.createAccessSubModel(JSONData); };
var sizeObj_APA = function sizeObj_APA(JSONData) { ICAPA.createSize(JSONData); };
var wheelObj_APA = function wheelObj_APA(JSONData) { ICAPP.createWheel(JSONData); };
var tireObj_APA = function tireObj_APA(JSONData) { ICAPP.createTire(JSONData); };
var imgObj_APA = function imgObj_APA(JSONData) { ICAPP.createImage(JSONData); };


/* global ICAPA, $ */
var intID = 160;
var intConfigID = 13898;
var intYear = '0';
var intMake = '0';
var intModel = '0';
var intSizeID = '0';
var lastsize = '';
var strMakeAP = '';
var strModelAP = '';
var strSubModelOpt = '';
var intSubModel = '';
var strSizeText = '';
var page = '';

var url = '';
var ajaxPage = false;

var ICAPP = (function ICAPP() {
  var setSession = function setSession(val, type, boxVal, text, product) {
    var dataString = 'funct=' + type + '&' + type + '=' + val + '&box=' + boxVal + '&text=' + text;
    var wheelImageID = arguments[5];
    var wheelID = arguments[6];
    var finishID = arguments[7];
    $.ajax({
      type: 'POST',
      url: '/ajax/createSession.cfm',
      data: dataString,
      success: function success(data) {
        if (data === 'expired') {
          window.location.reload();
        } else {
          if (type === 'size') {
            if (product === 'tires') {
              window.location.href = '/tires/?fit=1&sizeID=' + intSizeID;
            } else if (product === 'fitmentCheck') {
              $.magnificPopup.open({
                items: {
                  src: '<div class="white-popup processing">' +
                    '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
                    '</div>'
                },
                type: 'inline',
                modal: true
              });
              var fitmentCheckURL = '/ajax/fitmentCheck.cfm?SizeID=' + intSizeID + '&wheelImageID=' + wheelImageID + '&wheelID=' + wheelID + '&finishID=' + finishID;
              $.ajax({
                url: fitmentCheckURL,
                success: function (data) {
                  $('#fitmentCheckResults').html(data);
                  $.magnificPopup.open({
                    items: {
                      src: '#fitmentCheckResults',
                      type: 'inline'
                    }
                  });
                }
              });
            } else {
              window.location.href = '/wheels/?fit=1&sizeID=' + intSizeID;
            }
          } else if (type === 'clear') {
            $('.year').val('');
            $('.make').val('');
            $('.make').attr('disabled', 'disabled');
            $('.model').val('');
            $('.model').attr('disabled', 'disabled');
            $('.submodel').val('');
            $('.submodel').attr('disabled', 'disabled');
            $('.size').val('');
            $('.size').attr('disabled', 'disabled');
            $('.divResult').html('');
            $('.stryear').html('');
            $('.strmake').html('');
            $('.strmodel').html('');
            $('.strsubmodelopt').html('');
            $('.strsizetext').html('');
            $('.displayVehicle').detach();
            if (window.location.href.indexOf('fit') > -1) {
              if (product === 'tires') {
                window.location.href = '/tire-brands/';
              } else
                window.location.href = '/wheel-brands/';
            }
          }
        }

      }
    });
  };

  var initChange = function initChange() {
    $('.year').change(function changeYear() {
      intYear = $(this).val();
      if (intYear !== '') {
        url = 'ap-makes.cfm?yearid=' + intYear + '&configID=' + intConfigID +
          '&output=json&callback=makeObj';
        intMake = '0';
        intModel = '0';
        intSubModel = '0';
        ICAPA.getJSON(url);
        setSession(intYear, 'year', $('.year').html());
        $('.stryear').html('');
        $('.strmake').html('');
        $('.strmodel').html('');
        $('.strsubmodelopt').html('');
        $('.strsizetext').html('');
      } else {
        $('.make').attr('disabled', 'disabled');
        $('.model').attr('disabled', 'disabled');
        $('.submodel').attr('disabled', 'disabled');
      }
    });

    $('.make').change(function changeMake() {
      intMake = $(this).val();
      strMakeAP = $(this).find('option:selected').text();
      if (intMake !== '') {
        url = 'ap-models.cfm?yearid=' + intYear + '&makeid=' + intMake +
          '&configID=' + intConfigID + '&output=json&callback=modelObj';
        intModel = '0';
        intSubModel = '0';
        ICAPA.getJSON(url);
        setSession(intMake, 'make', $('.make').html(), strMakeAP);
        $('.strmake').html('');
        $('.strmodel').html('');
        $('.strsubmodelopt').html('');
        $('.strsizetext').html('');
      } else {
        $('.model').attr('disabled', 'disabled');
        $('.submodel').attr('disabled', 'disabled');
      }
    });

    $('.model').change(function changeModel() {
      intModel = $(this).val();
      strModelAP = $(this).find('option:selected').text();
      if (intModel !== '') {
        url = 'ap-submodels.cfm?yearid=' + intYear + '&makeid=' + intMake +
          '&modelid=' + intModel + '&output=json&callback=submodelObj';
        intSubModel = '0';
        ICAPA.getJSON(url);
        setSession(intModel, 'model', $('.model').html(), strModelAP);
        $('.strmodel').html('');
        $('.strsubmodelopt').html('');
        $('.strsizetext').html('');
      } else {
        $('.submodel').attr('disabled', 'disabled');
      }
    });

    $('.submodel').change(function changeSubmodel() {
      intSubModel = $(this).val();
      strSubModelOpt = $(this).find('option:selected').text();
      if (intSubModel !== '') {
        url = 'ap-sizes.cfm?yearid=' + intYear + '&makeid=' + intMake + '&modelid=' + intModel +
          '&submodel=' + intSubModel + '&id=' + intID + '&configId=' + intConfigID;
        ICAPA.getJSON(url);
        setSession(intSubModel, 'submodel', $('.submodel').html(), strSubModelOpt);
        $('.strsubmodelopt').html('');
        $('.strsizetext').html('');
      }
    });

    $('.size').change(function changeSize() {
      var intIndex = $(this).val();
      strSizeText = $(this).find('option:selected').text();
      intSizeID = intIndex;
      if (intIndex !== '') {
        lastsize = intIndex;
        $.magnificPopup.open({
          items: {
            src: '<div class="white-popup processing">' +
              '<i class="fa fa-spinner fa-spin fa-4x"></i>' +
              '</div>'
          },
          type: 'inline',
          modal: true
        });
        if ($(this).parent().attr('id') === 'tireFitment-popup' || $(this).parent().attr('id') === 'tireFitment-selectors') {
          setSession(intIndex, 'size', $('.size').html(), strSizeText, 'tires');
        } else if ($(this).parent().attr('id') === 'checkFitment-popup') {
          var wheelImageID = $(this).parent().data('wheelimageid');
          var wheelid = $(this).parent().data('wheelid');
          var finishid = $(this).parent().data('finishid');
          setSession(intIndex, 'size', $('.size').html(), strSizeText, 'fitmentCheck', wheelImageID, wheelid, finishid);
        } else {
          setSession(intIndex, 'size', $('.size').html(), strSizeText);
        }
      } else {
        $('.size option[value=' + lastsize + ']').attr('selected', 'selected');
      }
    });
  };

  return {
    initChange: initChange,
    setSession: setSession
  };
}());
$(function () {
  ICAPA.getJSON('ap-years.cfm?configID=' + intConfigID);
})
  ;
