///// Video slider /////

if ($('.js-home-slider').length) {
  let frontPageSlider = $('.js-home-slider');
  frontPageSlider.slick({
      infinite:true,
      autoplay: false,
      dots: false,
      arrows: false,
      adaptiveHeight: true,
      autoplaySpeed: 4000,
      responsive: [{
        breakpoint: 600,
        settings: {
          autoplay: true,
          adaptiveHeight: false
        }
      }]
  });
  if ( $(window).width() < 640 ) {
    frontPageSlider.slick('slickRemove', 0);
  } else {
    
    // 2. This code loads the IFrame Player API code asynchronously.
    var tag = document.createElement('script');

    tag.src = "//www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.
    var player;

    function onYouTubeIframeAPIReady() {
      player = new YT.Player('player', {
        videoId: document.getElementById('player').dataset.id,
        playerVars: {
          controls: 0,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
        },
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      });
    }
    // 4. The API will call this function when the video player is ready.
    function onPlayerReady(event) {
      player.mute();
      event.target.playVideo();
    }

    // 5. The API calls this function when the player's state changes.
    function onPlayerStateChange(e) {
      if (e.data == 0) {
        frontPageSlider.slick('slickNext');
        frontPageSlider.slick('slickPlay');
      }
    }

    $('.js-home-slider').on('afterChange', function (event, slick, currentSlide, nextSlide) {
      if (currentSlide === 0) {
        frontPageSlider.slick('slickPause');
        player.playVideo();
      }
    });
    
  }
  
  


}

(function setbackgroundLink() {
  if ($('.backgroundLink').length > 0) {
    $('.backgroundLink').attr('href', $('.backgroundSlide--large .slick-active').data('link'));
  }
})();

$('.js-home-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
  var getLink = $(slick.$slides.get(nextSlide)).data('link');
  $('.js-home-slider').attr('href', getLink);
});

///// END : Video Slider /////




if ($('.hp').length) {
    if ($(window).width() < 640) {
        $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
        $('.js-footer-social').insertAfter('.support-list');
    }

    var moveHTML = debounce(function () {
        if ($(window).width() < 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
            $('.js-footer-social').insertAfter('.support-list');
        }
        if ($(window).width() > 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__header');
            $('.js-footer-social').insertAfter('.footer__about');
        }
    }, 500);

    window.addEventListener('resize', moveHTML);
}




// Featured Wheels
$(window).load(function(){
    var $showNumber = 5;
    if($(window).width() <= 667) {
        $showNumber = 1;
    } else {
        $showNumber = 5;
    }
    $('#featured-slider').slick({
        autoplay: true,
        dots: false, 
        arrows: true,
        slidesToShow: $showNumber,
        swipeToSlide: true,
        nextArrow: '<button class="featured-next featured-arrow" aria-label="Next" type="button"><img src="/images/home/next-arrow.png" /></button>',
        prevArrow: '<button class="featured-prev featured-arrow" aria-label="Prev" type="button"><img src="/images/home/prev-arrow.png" /></button>',
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false
            }
          }
        ]
    });
});


/*if($('.sticky-container.hp').length) {
  var distanceFromTop = 0;
  var windowWidth = $(window).width();
  if (windowWidth > 1024) {
    $(window).scroll(function(){
      distanceFromTop = $(this).scrollTop();
      if (distanceFromTop > 10) {
        $('.sticky-container.hp').css('opacity','1');
      } else {
        $('.sticky-container.hp').css('opacity','0');
      }
    });
  }
}*/