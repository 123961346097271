let iconCart = iconCart || {};
/**
 * Cart Functions
 */
iconCart = (function () {
  let basket = [];

  return {
    getCart: function () {
      return basket;
    },
    emptyCart: function () {
      basket = [];
      // console.log('basket cleared: ' + basket);
    },
    addToCart: function (item) {
      basket.push({
        ...item,
      });
      // console.table(basket);
    },
    removeFromCart: function (id) {
      basket = basket.filter(item => {
        return item['partnumber'] !== id;
      });
      // console.table(basket);
    },
  };
})();

/**
 * Helper Functions
 */
function resetCheckboxes(className) {
  let checkboxes = document.querySelectorAll('.' + className);
  checkboxes.forEach((checkbox) => {
    checkbox.checked = false;
  })
}

function resetCart(event) {
  resetCheckboxes('checkbox-accessory');
  iconCart.emptyCart();
}

function getPackagePopupContent(URL, funct) {
  var strDomainAP = '/ajax/' + URL + '&funct=' + funct;
  try {
    $.ajax({
      url: strDomainAP,
      success: function (data) {
        $('.packageContent').html(data);
        $.magnificPopup.open({
          items: {
            src: '#package-popup',
            type: 'inline',
          },
          callbacks: {
            close: function () {
              resetCart(event)
            }
          }
        });
      },
    });
  } catch (err) {
    window.location.reload();
  }
}

function openAccessoryPopup() {
  $.magnificPopup.open({
    items: {
      src: '#accessory-popup',
      type: 'inline',
    },
    callbacks: {
      close: function () {
        resetCart(event)
      }
    }
  });
}

function addInitialItemsToCart(selector) {
  let items = document.querySelectorAll(selector);
  items.forEach(item => {
    let data = JSON.parse(JSON.stringify(item.dataset));
    iconCart.addToCart(data);
  });
}

function createFormInputs(basket) {
  return basket.reduce((acc, item, index) => {
    for (var data in item) {
      let itemName = index > 0 ? (index + 1) + ':' + data : data;
      acc += `<input name="${itemName}" value="${item[data]}">`;
    }
    return acc;
  }, '');
};

function insertElementInnerHTML(selector, html) {
  let items = document.querySelector(selector);
  items.innerHTML += html;
}

function deleteElementInnerHTML(selector) {
  let items = document.querySelector(selector);
  items.innerHTML = '';
}

function getTireItems() {
  let tireDropDowns = document.querySelectorAll('.tireSelect');
  tireDropDowns.forEach(item => {
    let option = item.querySelector('option:checked');
    if (option.value !== '') {
      let data = JSON.parse(JSON.stringify(option.dataset));
      iconCart.addToCart(data);
    }
  });
}
/**
 * Event Handlers
 */
$('.cartSubmit').on('click', function (e) {
  e.preventDefault();

  addInitialItemsToCart('.initial-item');

  let basket = iconCart.getCart();
  let htmlStringOfInputs = createFormInputs(basket);
  insertElementInnerHTML('.cart-items', htmlStringOfInputs);

  $.magnificPopup.close();

  $('#cart-wheelForm').submit();

  deleteElementInnerHTML('.cart-items');
});

$('.tireCartSubmit').on('click', function (e) {
  e.preventDefault();

  getTireItems();

  let basket = iconCart.getCart();
  let htmlStringOfInputs = createFormInputs(basket);
  insertElementInnerHTML('.cart-items', htmlStringOfInputs);

  $.magnificPopup.close();

  $('#cart-tireForm').submit();

  deleteElementInnerHTML('.cart-items');
});

$('.tireCart-submit').on('click', function (e) {
  e.preventDefault();
  $('#cart-singleTireForm').submit();

});

$('.accessoryContent').on('click', '.addAccessory', function (event) {
  let accessory = this;
  let checkboxIsChecked = accessory.checked;

  if (checkboxIsChecked) {
    return iconCart.addToCart(accessory.dataset);
  }

  return iconCart.removeFromCart(accessory.dataset.partnumber);
})

$('.packageContent').on('click', '.addTire, .cancelBtn', function (event) {
  let clickedEl = this;
  if (clickedEl.classList.contains('addTire')) {
    event.stopPropagation();
    let selectedTire = clickedEl.closest('.tireDetail');

    selectedTire.querySelectorAll('.pkg option:checked').forEach(item => {
      let data = JSON.parse(JSON.stringify(item.dataset));
      iconCart.addToCart(data);
    });

    openAccessoryPopup();
  }
  if (clickedEl.classList.contains('cancelBtn')) {
    $.magnificPopup.close();
  }
});

$('.accessory-open').on('click', function () {
  openAccessoryPopup();
});

$('.package-open').on('click', function () {
  let data = $('.tire-data').data();
  let configID = data.configID;
  let front = data.front;
  let rear = data.rear;
  let url = `getTirePackage.cfm?tireSize=${front}&tireSizeRear=${rear}&configID=${configID}`;
  getPackagePopupContent(url, 'GetTirePackageQRY');
});

// Match package select drop downs
$('.packageContent').on('change', '.syncSelect', function () {
  var firstSelect = '';
  var secondSelect = '';

  firstSelect = $('option:selected', this).attr('value');
  secondSelect = $(this)
    .closest('.drop')
    .siblings('.drop')
    .children('select')
    .children('option')
  secondSelect.each(function () {
    if ($(this).attr('value') == firstSelect) {
      $(this).prop('selected', true);
    }
  });

});