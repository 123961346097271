function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

// header
  let headerBar = $('.top-bar-title');
  let browserSize = $(window).width();
$('button.menu-icon').click(function(){
  if(headerBar.hasClass('menu-open')) {
    headerBar.removeClass('menu-open');
  } else {
    headerBar.addClass('menu-open');
  }
});

$(window).resize(function(){
  browserSize = $(window).width();
  console.log('this is the browser size: ', browserSize);
  if(browserSize > 1023) {
    headerBar.removeClass('menu-open');
  }
});